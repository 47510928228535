import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PayrollList from "./Payroll/PayrollList";
import {Link, NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {PayrollLayout} from "./Payroll/PayrollLayout";

export function Admin() {
    const location = useLocation();

    return (<div style={{paddingTop: 5}}>
        <Nav fill variant="tabs" defaultActiveKey="payroll">
            <Nav.Item>
                <Nav.Link as={Link} to={"payroll"} eventKey="payroll">Зарплатные
                    ведомости</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"package"} eventKey="package">Пакеты
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"kpi"} eventKey="kpi">KPI
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"matrix"} eventKey="matrix">Матрицы
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"commission"} eventKey="commission">Комиссионные
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"bonusPenalty"} eventKey="bonusPenalty">Премирование / Депремирование
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={"employeeSettings"} eventKey="employeeSettings">Настройки сотрудников
                </Nav.Link>
            </Nav.Item>
        </Nav>
        <Routes>
            <Route path={"payroll"} element={<PayrollList/>}/>
            <Route path={"payroll/edit/:id"} element={<PayrollLayout/>}/>
        </Routes>
    </div>)
}