import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {Link, NavLink, useSearchParams, useRouteLoaderData} from "react-router-dom";
import {getUserContext} from "../../../fetchApi";
import {PayrollAddModal} from "./PayrollAddModal";
import {PayrollLayout} from "./PayrollLayout";

const PayrollList = () => {
    const [context, setContext] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        startDate: '',
        endDate: '',
        employees: [],
    });

    useEffect(() => {
        getUserContext(searchParams.get("contextKey")).then(response => setContext(response))

    }, []);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSelectChange = (event) => {
        const {value} = event.target;
        setFormData({...formData, employees: value});
    };


    return (
        <div style={{padding: 5}}>
            <PayrollAddModal showModal={showModal} handleCloseModal={() => handleCloseModal()}/>
            <div className="d-flex justify-content-between mb-3">

                <Button variant="primary" onClick={handleShowModal}>Добавить</Button>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Период</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><NavLink to={"edit/1"}>Report 1</NavLink></td>
                    <td>2023-01-01 - 2023-01-31</td>

                    <td>Complete</td>
                </tr>
                <tr>
                    <td>Report 2</td>
                    <td>2023-02-01 - 2023-02-28</td>
                    <td>In progress</td>
                </tr>
                </tbody>
            </table>

        </div>
    );
}


export default PayrollList;
