import {Form, Table} from "react-bootstrap";

export function MatrixLayout(props) {
    const kpiTyper = (kpiType) => {
        switch (kpiType) {
            case 'sales_kpis':
                return 'KPI показателей продаж'

            default:
                return 'KPI произвольный'

        }

    }
    return (
        <>

            <b>{kpiTyper(props.kpiType)}:</b>
            <Table striped responsive="md">
                <thead>
                <tr>
                    <th>id</th>
                    <th>Название</th>
                    <th>Нормальное значение</th>
                    <th>Фактическое значение</th>
                    <th>Удельный вес</th>
                    <th>Индекс KPI в матрице</th>
                    <th>Доля выплаты</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(props.kpiTypeList).map(([kpiId, kpiData], idx) => {
                    return (

                        <tr>
                            <td>{kpiId}</td>
                            <td>{kpiData?.name}</td>
                            <td>{kpiData?.normal_value}</td>
                            <td><Form.Control type="text"
                                              value={kpiData?.actual_value}
                                              name="name"
                                              style={{
                                                  maxWidth: "100%",
                                                  width: "8em"
                                              }}/>
                            </td>
                            <td>{kpiData?.structure_percent}</td>
                            <td>{kpiData?.kpi_index}</td>
                            <td>{kpiData?.bonus_share}</td>
                        </tr>

                    )
                })}
                </tbody>

            </Table>
        </>
    )
}