import {Accordion, Button, ButtonGroup, Table} from "react-bootstrap";
import {BonusPackageLayout} from "./BonusPackageLayout";

export function PayrollLayout(props) {
    const data = require('./payroll_response_example.json');

    return (<div style={{padding: 10}}>

        <h3>{data?.name}(Период: {data?.moment_start} - {data?.moment_end})</h3>

        {Object.entries(data.body)?.map(([employeeId, employeeData], idx) => {
            return (<Accordion defaultActiveKey="0" alwaysOpen>
                    <Accordion.Item eventKey={idx}>
                        <Accordion.Header>{employeeData?.employee_name}</Accordion.Header>
                        <Accordion.Body>
                            <Accordion alwaysOpen>
                                <Accordion.Item eventKey="packagesTab">
                                    <Accordion.Header>Пакеты</Accordion.Header>
                                    <Accordion.Body>
                                        {Object.entries(employeeData.packages)?.map(([bonusPackageId, bonusPackageData], idx) => {
                                            return (<BonusPackageLayout idx={idx} bonusPackage={bonusPackageId}
                                                                    bonusPackageData={bonusPackageData}/>
                                            )

                                        })}
                                    </Accordion.Body>

                                </Accordion.Item>
                                <Accordion.Item eventKey="bonus">
                                    <Accordion.Header>Премирование</Accordion.Header>
                                    <Accordion.Body>Таблица премий</Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="penalty">
                                    <Accordion.Header>Депремирование</Accordion.Header>
                                    <Accordion.Body>Таблица депремирования</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div style={{display: "flex", flexDirection: "column", padding: 10}}>
                                <span><b>Оклад:</b> {employeeData?.salary_base_amount} руб.</span>
                                <span><b>Выплаты по KPI:</b> {employeeData?.kpi_bonus_amount} руб.</span>
                                <span><b>Штрафы / Бонусы:</b> {employeeData?.bonus_penalty_amount} руб.</span>
                                <span><b>Cумма комиссионных:</b> {employeeData?.commission_amount} руб.</span>
                                <span><b>Итого к выплате:</b> {employeeData?.total_employee_salary} руб</span>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

            )
        })}

        <div style={{paddingTop: 10}}>

            <Button> Сохранить</Button>
            <Button variant="light"> Закрыть</Button>
        </div>
    </div>)
}

