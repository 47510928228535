import {Button, Form, Modal} from "react-bootstrap";
import React from "react";

export function PayrollAddModal(props) {


    //
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Modal show={props?.showModal} onHide={props?.handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add new Vedomsoti</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" />
                    </Form.Group>

                    <Form.Group controlId="formStartDate">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="startDate"/>
                    </Form.Group>

                    <Form.Group controlId="formEndDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" name="endDate" />
                    </Form.Group>

                    <Form.Group controlId="formEmployees">
                        <Form.Label>Employees</Form.Label>
                        <Form.Control as="select" multiple name="employees" >
                            <option value="employee1">Employee 1</option>
                            <option value="employee2">Employee 2</option>
                            <option value="employee3">Employee 3</option>
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit">Add</Button>
                </Form>
            </Modal.Body>
        </Modal>)
}