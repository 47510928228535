import logo from './logo.svg';
import './App.css';
import React from "react";
import PayrollList from "./Pages/Admin/Payroll/PayrollList";
import {Main} from "./Pages/Main";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {TopNavbar} from "./Pages/TopNavbar";
import {Admin} from "./Pages/Admin/Admin";
import {PayrollLayout} from "./Pages/Admin/Payroll/PayrollLayout";

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("contextKey")) {
        console.log(queryParams.get("contextKey"));
        return (<h1> NO CONTEXT</h1>)
    } else {
        return (
            <BrowserRouter>
                <main>
                    <TopNavbar/>
                    <Routes>
                        <Route path="/" element={<Main/>}/>
                        <Route path="/111" element={<PayrollList/>}/>
                        <Route path="/admin/*" element={<Admin/>}/>
                    </Routes>
                </main>
            </BrowserRouter>


        )
    }
}

export default App