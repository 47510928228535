import {Accordion, Table, Form} from "react-bootstrap";
import {MatrixLayout} from "./MatrixLayout";

export function BonusPackageLayout(props) {
    return (
        <Accordion>
            <Accordion.Item eventKey="packagesList">
                <Accordion.Header>{props.bonusPackageData?.package_name} </Accordion.Header>
                <Accordion.Body>
                    {Object.entries(props.bonusPackageData?.matrices_data).map(([matrixId, matrixData], idx) => {
                        return (
                            <Accordion>
                                <Accordion.Item eventKey={`matrix-${matrixId}`}>
                                    <Accordion.Header>
                                        {matrixData ? <>{matrixData.matrix_name}</> : null}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {Object.entries(matrixData?.kpis_data)?.map(([kpiType, kpiTypeList], idx) => {
                                            return (<MatrixLayout kpiType={kpiType} kpiTypeList={kpiTypeList}/>)
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    })}
                    {Object.entries(props.bonusPackageData?.commissions_data)?.map(([commissionId, commissionData], idx) => {
                        return (
                            <Accordion>
                                <Accordion.Item eventKey={`commission-${commissionId}`}>
                                    <Accordion.Header>
                                        Комиссионные
                                    </Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        )
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    )
}


