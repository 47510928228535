let apiUrl = 'http://127.0.0.1:8000/api/v1';
if (process.env.NODE_ENV === 'production'){
    apiUrl = 'http://83.222.8.15:9500/api/v1';
}

export default apiUrl;

export const entities = {
    msService: '/ms_service'
}
